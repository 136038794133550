import React from "react";
import {
  Box,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  useDisclosure,
  Badge,
  Image,
  Input,
  keyframes,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Divider,
  Avatar,
  SimpleGrid
} from '@chakra-ui/react';
import { theme } from '../../styles/theme';
import { AuthContext } from '../../Context/AuthContext';
import { UserContext } from '../../Context/UserContext';
import { CityContext } from '../../Context/CityContext';
import { CartContext } from '../../Context/CartContext';
import { Search } from '../Search';
import { useContext, useEffect, useState, useRef } from 'react';
import { FaMapMarkerAlt, FaBeer, FaPercentage   } from 'react-icons/fa';
import { RiUser3Line, RiSearchLine, RiCloseCircleLine, RiStarLine, RiRecordCircleLine, RiTruckLine, RiShoppingBasket2Line, RiMenu2Fill, RiLogoutBoxLine, RiLoginBoxLine, RiQuestionLine, RiVipCrown2Line } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { api } from "../../services/api";
import { useNavigate, useLocation } from 'react-router-dom';
import logo from '../../assets/images/logo-boozen.png';
import { FaInstagram } from "react-icons/fa";
import { CartComponent } from '../CartComponent';

const Links = ['HOME', 'SOBRE', 'QUEM SOMOS'];
const NavLink = ({ children }) => (
  <Link
    px={2}
    py={1}
    fontSize={'18px'}
    rounded={'md'}
    color={theme.colors.black}
    _hover={{
      backgroundColor: theme.colors.primary,
    }}
    style={{ textDecoration: 'none' }}
    href={'#'}>
    {children}
  </Link>
);

const Navbar = ({ onFilterProducts }) => {
  const { user, signOutUser, isAuthenticated } = useContext(AuthContext);
  const { userInfos, userCity, userPhoto } = useContext(UserContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placement, setPlacement] = React.useState('right')
  const [cartBatchesCounter, setCartBatchesCounter] = useState(0)
  const [selectLocation, setSelectLocation] = useState(false)
  const navigate = useNavigate();
  const [cartCounter, setCartCounter] = useState("")
  const { cityName, fetchCEP } = useContext(CityContext);
  const [cep, setCep] = useState('');
  const [showInput, setShowInput] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);
  const location = useLocation();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const items = [
    { text: "Maior loja de drinks prontos do Brasil", icon: <RiVipCrown2Line/> },
    { text: "Maior variedade de chopes de BH, com o menor preço do mercado" , icon: <FaBeer/> },
    { text: "GANHE 5% de desconto em sua primeira compra", icon: <FaPercentage /> },
    { text: "Frete grátis acima de R$120,00", icon: <RiTruckLine />}
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // State for the menu drawer
  const [isCartOpen, setIsCartOpen] = useState(false); // State for the cart drawer

  const handleMenuOpen = () => setIsMenuOpen(true);
  const handleMenuClose = () => setIsMenuOpen(false);

  const handleCartOpen = () => setIsCartOpen(true);
  const handleCartClose = () => setIsCartOpen(false);


  const [showFirstBox, setShowFirstBox] = useState(true);
  const [showSecondBox, setShowSecondBox] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [textWidth, setTextWidth] = useState(0);


  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.scrollY;
      if (currentScroll > scrollPosition) {
        setShowFirstBox(false);
        setShowSecondBox(true);
      } else {
        if (currentScroll === 0) {
          setShowFirstBox(true);
          setShowSecondBox(false);
        }
      }
      setScrollPosition(currentScroll);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrollPosition]);



  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [items.length]);;


  const handleCepChange = (e) => {
    setCep(e.target.value);
  };

  const handleSubmitCep = (e) => {
    e.preventDefault();
    console.log(cep);
    // debugger
    if (cep) {
      fetchCEP(cep);
      setCep(cep);
      setShowInput(false);
    }
  };


  const isHomePage = location.pathname === '/';

  async function fetchCartBatches() {
    const cartData = localStorage.getItem('guestCart');
      let guestCart = [];
      try {
        if (cartData) {
          guestCart = JSON.parse(cartData);
        }
      } catch (error) {
        console.error("Error parsing guestCart from localStorage:", error);
      }
      setCartBatchesCounter(guestCart.length);
      localStorage.setItem("cartItems", guestCart.length.toString());  // Update localStorage counter
  }
  const { cartItemsCounter } = useContext(CartContext);

  useEffect(() => {
    isAuthenticated();
  }, [user])



  useEffect(() => {
    const getCartCounter = () => {
      setCartCounter(localStorage.getItem('cartItems') || '0');
    };
    fetchCartBatches();
    getCartCounter();
    const counterInterval = setInterval(() => {
      getCartCounter();
    }, 100);
    return () => {
      clearInterval(counterInterval);
    };
  }, []);


  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputRef.current && !inputRef.current.contains(event.target)) {
        setShowInput(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  function handleMenu() {
    return (
      <>
        {user !== undefined && user?.access === "admin" && (
          <MenuItem
            color={theme.colors.darkGray}
            fontWeight='bold'
            _hover={{bg: "#f1f1f1", color: "black"}}
          >
            <Link to='/admin/dashboard' width='100%'>
              Painel Do Admin
            </Link>
          </MenuItem>
        )}

        {user !== undefined ? (
          <>
            <MenuItem
              inset={{base: '0px auto auto -2000px !important', md: '0px auto auto 0px'}}
              color={theme.colors.darkGray}
              fontWeight='bold'
              _hover={{bg: "#f1f1f1", color: "black"}}
            >
              <Link to={`/perfil/${user.id}`} style={{ width: '100%' }}>
                Perfil
              </Link>
            </MenuItem>
            <MenuItem
              color={theme.colors.darkGray}
              fontWeight='bold'
              _hover={{bg: "#f1f1f1", color: "black"}}
              onClick={() => signOutUser()}
            >
              Sair
            </MenuItem>
          </>
        ) : (
          <>
            <MenuItem
              inset={{base: '0px auto auto -2000px !important', md: '0px auto auto 0px'}}
              color={theme.colors.darkGray}
              fontWeight='bold'
              _hover={{bg: "#f1f1f1", color: "black"}}
            >
              <Link to='/sign_in' style={{ width: '100%' }}>
                Entrar
              </Link>
            </MenuItem>
            <MenuItem
              color={theme.colors.darkGray}
              fontWeight='bold'
              _hover={{bg: "#f1f1f1", color: "black"}}
            >
              <Link to='/sign_up' style={{ width: '100%' }}>
                Cadastrar
              </Link>
            </MenuItem>
          </>
        )}
      </>
    )
  }


  const handleBestSellings = async () => {
    try {
      let url = `/api/v1/products?&filter=${JSON.stringify(["Mais Vendidos"])}&city_name=${cityName}`;
      const response = await api.get(url);
      const productsData = response.data.products;
      navigate(`/produtos?&filter=${encodeURIComponent(JSON.stringify(["Mais Vendidos"]))}&city_name=${cityName}`, { state: { products: productsData } });
    } catch (error) {
      console.error(error);
    }
  };
  const slideLeft = keyframes`
    0% { transform: translateX(100%); }
    100% { transform: translateX(0%); }
  `;

  const animation = `${slideLeft} 1s ease-in-out`;
  const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);


  const scrollingAnimation = keyframes`
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  `;

  const text = "50% de desconto em produtos selecionados"; // The text to repeat
  const duration = "90s"

  useEffect(() => {
    if (containerRef.current) {
      const singleTextWidth = containerRef.current.scrollWidth;
      setTextWidth(singleTextWidth);
    }
  }, []);

  const linksCategories = [
    {
      title: "CAMISETAS/ACESSÓRIOS",
      params: JSON.stringify(["Acessórios"]),
    },
    {
      title: "KITS",
      params: JSON.stringify(["Kits"]),

    },
  ]


  const handleScrollToStore = () => {
    navigate('/');

    setTimeout(() => {
      const storeSection = document.getElementById('storeSection');
      if (storeSection) {
        window.scrollTo({
          top: storeSection.offsetTop - 200,
          behavior: 'smooth'
        });
      }
    }, 0);
  };


  const typeFilters = [
    {
      cardTitle: "Chopp",
      param: JSON.stringify(["chopp", "Chopp"])

    },
    {
      cardTitle: "Drinks Prontos",
      param: JSON.stringify(["OUTRAS BEBIDAS", "BEBIDAS MISTAS"])

    },
    {
      cardTitle: "Microcervejarias",
      param: JSON.stringify(["Microcervejarias", "microcervejarias", "microcervejaria", "Microcervejaria"]),

    },
    {
      cardTitle: "Cervejas",
      param: JSON.stringify(["CERVEJA", "Cerveja"])

    },
    {
      cardTitle: "Vinhos",
      param: JSON.stringify(["Vinho"])

    },
    {
      cardTitle: "Destilados",
      param: JSON.stringify(["DESTILADOS"])

    },
    {
      cardTitle: "Eventos",
      param: JSON.stringify(["Eventos, Buffets e Casamentos"])

    },
    {
      cardTitle: "KITS",
      param: JSON.stringify(["Kits"])

    },
    {
      cardTitle: "Não Alcoólicos",
      param: JSON.stringify(["NÃO ALCOOLICOS", "Não alcoólicos", "Não Alcoólicos", "NÃO ALCOOLICO", "Não alcóolico", "Não Alcóolico"])

    },
    {
      cardTitle: "Acessórios",
      param: JSON.stringify(["Acessórios"])

    },
    {
      cardTitle: "Projetos Sociais",
      param: JSON.stringify(["Projetos Sociais", "projetos sociais", "Projetos sociais"])

    },
    {
      cardTitle: "Mais Vendidos",
      param: JSON.stringify(
        ["Mais Vendidos"]
      ),

    }
  ];


  const typesOfCardToSelec = typeFilters.filter(card => {
    if (
      cityName === 'Belo Horizonte' ||
      cityName === 'Nova Lima' ||
      cityName === 'Contagem' ||
      cityName === 'Betim' ||
      cityName === 'Lagoa Santa'
    ) {
      return true;
    } else if (
      cityName !== 'Belo Horizonte' &&
      cityName !== 'Nova Lima' &&
      cityName !== 'Contagem' &&
      cityName !== 'Betim' &&
      cityName !== 'Lagoa Santa'
    ) {
      return (
        card.cardTitle === "Drinks Prontos" ||
        card.cardTitle === "Maiores Descontos" ||
        card.cardTitle === "Mais Vendidos"
      );
    }
    return false;
  });

  const openModal = () => {
    setIsOpenModal(true);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const searchBtncolor = isScrolled ? theme.colors.primaryGreen : theme.colors.primaryGreen;

  return (<>
    {showFirstBox && (<>
      <Box
        position="relative"
        overflow="hidden"
        width="100%"
        minHeight="auto"
        padding="1rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        background="green"
      >
        <Box
          display="flex"
          alignItems="center"
          gap="10px"
          fontSize="20px"
          position="absolute"
          animation={animation}
          textAlign="center"
          whiteSpace="normal"
          maxWidth="90%"
          overflow="hidden"
          padding={["1rem", "0.75rem"]}
          lineHeight={[".9rem", "1.5rem"]}
        >
          <Text color="white" fontSize={["0.9rem", "1rem"]}>{items[currentIndex].icon}</Text>
          <Text color="white" fontSize={["0.9rem", "1rem"]}>{items[currentIndex].text}</Text>
        </Box>
      </Box>

      <Box
        background="transparent"
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        height="10rem"
        flexWrap="wrap"
        gap="10px"
        position="absolute"
        top="1.9rem"
        left="0"
        right="0"
        zIndex="1000"
      >
        {/* MOBILE */}
        <Box display={{ base: 'flex', md: 'none' }} justifyContent="space-between" alignItems="center" width="100%" pt="2rem">
          <Button onClick={handleMenuOpen} bg="transparent" color={theme.colors.orange} display="flex" padding="0px" justifyContent="center" alignItems="center" position="relative" _hover={{ background: "transparent", color: "#D44120" }}>
            <RiMenu2Fill size={'35px'} color={theme.colors.logoOrange} />
          </Button>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
            <Link to={'/'}>
              <Image objectFit='cover' maxW={{ base: '130px', md: '200px' }} src={logo} />
            </Link>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center">
              {!showInput ? (
                <Text color={theme.colors.logoOrange} display="flex" alignItems="center" fontSize="15px" fontWeight="600"  onClick={() => setShowInput(true)}>
                  <FaMapMarkerAlt />
                  {cityName || "Selecione sua região"}
                </Text>
              ) : (
                <Box position="relative" display="flex" alignItems="center" as="form" onSubmit={handleSubmitCep}>
                  <Input
                    placeholder="Digite o CEP"
                    background="white"
                    size="sm"
                    borderRadius="20px"
                    height="25px"
                    onChange={handleCepChange}
                    color="black"
                    border="1px solid black"
                    _hover={{ borderColor: theme.colors.primary }}
                    _focus={{ outline: 'none', boxShadow: 'none' }}
                    pr="2rem" // Add padding for the button space
                  />
                  <Button
                    type="submit"
                    position="absolute"
                    right="0px"
                    bg="transparent"
                    zIndex="10000"
                    color="black"
                    _hover={{ color: theme.colors.primary, bg: 'transparent' }}
                    height="100%"
                  >
                    <RiSearchLine />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
          <Box display="flex" justifyContent="end" alignItems="cneter">
            <Menu>
              <MenuButton
                bg="transparent"
                display={{ base: 'none', md: 'block' }}
                justifyContent="center"
                alignItems="center"
                padding="0px"
              >
                {user ?
                  <Avatar
                    // size={{ base: 'md', md: 'md' }}
                    width={{base: "40px", md: "50px"}}
                    height={{base: "40px", md: "50px"}}
                    display='block'
                    max-width='100%'
                    border={`2px solid ${theme.colors.logoOrange}`}
                    src={localStorage.getItem('userPhoto') !== "" ? localStorage.getItem('userPhoto') : 'https://img.freepik.com/free-icon/user_318-159711.jpg'}
                  />
                :
                  <RiUser3Line color={theme.colors.logoOrange} size={"35px"}/>
                }
              </MenuButton>
              <MenuList zIndex='10000' borderColor={theme.colors.orange}>
                {handleMenu()}
              </MenuList>
            </Menu>
            <Button mt="5px" onClick={handleCartOpen} bg="transparent" color={theme.colors.orange} display="flex" padding="0px" justifyContent="center" alignItems="center" position="relative" _hover={{ background: "transparent", color: "#D44120" }}>
              <Badge background="green" color="white" width="20px" height="20px" position="absolute" top="-40%" right="25%" fontSize="15px" borderRadius="50%">
                {cartItemsCounter}
              </Badge>
              <RiShoppingBasket2Line size={'35px'} color={theme.colors.logoOrange} />
            </Button>
          </Box>
        </Box>

        {/* PC COMPUTER */}
        <Box display={{ base: 'none', md: 'flex' }} justifyContent="center" alignItems="center" flexDirection="column">
          <Link to={'/'}>
            <Image objectFit='cover' maxW={{ base: '130px', md: '180px' }} src={logo} />
          </Link>
          <Box flex="1" display="flex" justifyContent="center" alignItems="center">
            {!showInput ? (
              <Text color={theme.colors.logoOrange} textshadow={isHomePage ? '1px 1px 2px black' : 'none'} display="flex" alignItems="center" fontSize="15px" fontWeight="600" onClick={() => setShowInput(true)}>
                <FaMapMarkerAlt />
                {cityName || "Selecione sua região"}
              </Text>
            ) : (
              <Box position="relative" display="flex" alignItems="center" as="form" ref={inputRef} onSubmit={handleSubmitCep}>
                <Input
                  placeholder="Digite o CEP"
                  background="white"
                  size="sm"
                  borderRadius="20px"
                  height="25px"
                  onChange={handleCepChange}
                  color="black"
                  border="1px solid black"
                  _hover={{ borderColor: theme.colors.primary }}
                  _focus={{ outline: 'none', boxShadow: 'none' }}
                  pr="2rem" // Add padding for the button space
                />
                <Button
                  type="submit"
                  position="absolute"
                  right="0px"
                  bg="transparent"
                  zIndex="10000"
                  color="black"
                  _hover={{ color: theme.colors.primary, bg: 'transparent' }}
                  height="100%"
                >
                  <RiSearchLine />
                </Button>
              </Box>

            )}
          </Box>
        </Box>

        <Box display={{ base: 'none', md: 'flex' }} justifyContent="space-around" alignItems="center">
          {linksCategories.map((card, index) => {
            return (
              <Button
                key={`link-${index}`}
                style={{ textDecoration: 'none', fontWeight: 'bold', color: `${theme.colors.logoOrange}`, textshadow: `${isHomePage ? '1px 1px 2px black' : 'none'}` }}
                background='transparent'
                _hover={{ bg: "transparent", transform: "scale(1.08)", }}
              >
                <Link to={`/produtos?filter=${encodeURIComponent(card.params)}`} key={index}>
                  {card.title}
                </Link>
              </Button>
            )
          })}
          <Button
            style={{ textDecoration: 'none', fontWeight: 'bold', color: `${theme.colors.logoOrange}`, textshadow: `${isHomePage ? '1px 1px 2px black' : 'none'}` }}
            background='transparent'
            _hover={{ bg: "transparent", transform: "scale(1.08)", }}
            onClick={handleScrollToStore}
          >
            BEBIDAS
          </Button>
          <Button
            style={{ textDecoration: 'none', fontWeight: 'bold', color: `${theme.colors.logoOrange}`, textshadow: `${isHomePage ? '1px 1px 2px black' : 'none'}` }}
            background='transparent'
            _hover={{ bg: "transparent", transform: "scale(1.08)", }}
          >
            <Link to='/quem_somos'>QUEM SOMOS</Link>
          </Button>
          <Button
            style={{ textDecoration: 'none', fontWeight: 'bold', color: `${theme.colors.logoOrange}`, textshadow: `${isHomePage ? '1px 1px 2px black' : 'none'}` }}
            onClick={handleBestSellings}
            background='transparent'
            _hover={{ bg: "transparent", transform: "scale(1.08)", }}
          >
            DESTAQUES
          </Button>
        </Box>

        <Flex alignItems="center" gap="5px" width={{ base: '100%', md: '25%' }}>
          <Button onClick={openModal} bg="transparent" color={theme.colors.logoOrange}  display={{ base: 'none', md: 'block' }} padding="0px" justifyContent="center" alignItems="center" position="relative" _hover={{ background: "transparent", color: "#D44120" }}>
            <FaBeer size='30px' color={theme.colors.logoOrange} />
          </Button>
          {isOpenModal && (
            <Box
              position="fixed"
              top="0"
              left="0"
              width="100%"
              height="100%"
              bg="rgba(0, 0, 0, 0.5)"
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex="100000"
            >
              <Box
                bg="white"
                borderRadius="8px"
                boxShadow="lg"
                p={4}
                width={{ base: '90%', md: '50%' }}
              >
                <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
                  <Text fontSize="lg" fontWeight="bold" mb={0} color={theme.colors.secondaryGreen}>
                    Escolha o tipo
                  </Text>
                  <Button bg="transparent" color="red" _hover={{ bg: "transparent", transform: "scale(1.3)", }} onClick={closeModal}>
                    <RiCloseCircleLine />
                  </Button>
                </Box>
                <Divider/>
                <Box margin="2% 2% 2% 2%">
                  <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
                    {typesOfCardToSelec.map((filter, index) => (
                      <Link to={`/produtos?filter=${encodeURIComponent(filter.param)}&city_name=${cityName}`} key={index}>
                        <Button
                          border="1px solid"
                          borderColor={theme.colors.logoOrange}
                          color={theme.colors.logoOrange}
                          borderRadius="20px"
                          padding="5px 10px"
                          cursor="pointer"
                          width="100%"
                          backgroundColor="transparent"
                          marginTop="10px"
                          marginRight="10px"
                          _hover={{background: theme.colors.logoOrange, borderColor: theme.colors.logoOrange, color: "white"}}
                        >
                          {filter.cardTitle}
                        </Button>
                      </Link>
                    ))}
                  </SimpleGrid>
                </Box>
              </Box>
            </Box>
          )}
          <Box display={{base: "none", md: "block"}}>
            <Search/>
          </Box>
          <Menu>
            <MenuButton
              bg="transparent"
              display={{ base: 'none', md: 'block' }}
              justifyContent="center"
              alignItems="center"
              padding="0px"
            >
              {user ?
                <Avatar
                  size={{ base: 'sm', md: 'sm' }}
                  display='block'
                  max-width='100%'
                  border={`2px solid ${theme.colors.logoOrange}`}
                  src={localStorage.getItem('userPhoto') !== "" ? localStorage.getItem('userPhoto') : 'https://img.freepik.com/free-icon/user_318-159711.jpg'}
                />
              :
                <RiUser3Line color={theme.colors.logoOrange} size={"35px"}/>
              }
            </MenuButton>
            <MenuList zIndex='10000' borderColor={theme.colors.orange}>
              {handleMenu()}
            </MenuList>
          </Menu>
          <Button onClick={handleCartOpen} bg="transparent" color={theme.colors.logoOrange}  display={{ base: 'none', md: 'block' }} padding="0px" justifyContent="center" alignItems="center" position="relative" _hover={{ background: "transparent", color: "#D44120" }}>
            <Badge background={theme.colors.logoOrange} color="white" width="20px" height="20px" position="absolute" top="-40%" right="29%" fontSize="15px" borderRadius="50%">
              {cartItemsCounter}
            </Badge>
            <RiShoppingBasket2Line size={'35px'} color={theme.colors.logoOrange}  textshadow='1px 1px 2px black'/>
          </Button>
        </Flex>
      </Box>
    </>)}


    {/* WHEN SCROLL */}
    {showSecondBox && (<>
      <Box
        position="fixed"
        top={0}
        width="100%"
        zIndex={1000}
        height="auto"
        px={{base: "0px", md: "5rem"}}
        background={theme.colors.orange}
        py={{base: '1%', md: '1%'}}
      >
        <Flex justifyContent="space-around" alignItems="center">
          <Button onClick={handleMenuOpen} bg="transparent" color={theme.colors.orange} display="flex" padding="0px" justifyContent="center" alignItems="center" position="relative" _hover={{ background: "transparent", color: "#D44120" }}>
            <RiMenu2Fill size={'35px'} color={theme.colors.primaryGreen} />
          </Button>

          <Box display={{ base: 'flex', md: 'flex' }} justifyContent="center" alignItems="center" flexDirection="column" ms={{base: '7%', md: '0%'}}>
            <Link to={'/'}>
              <Image objectFit='cover' maxW={{ base: '140px', md: '180px' }} src={logo} />
            </Link>
            <Box flex="1" display="flex" justifyContent="center" alignItems="center">
              {!showInput ? (
                <Text color={theme.colors.primaryGreen} display="flex" alignItems="center" fontSize="15px" fontWeight="600" onClick={() => setShowInput(true)}>
                  <FaMapMarkerAlt />
                  {cityName || "Selecione sua região"}
                </Text>
              ) : (
                <Box position="relative" display="flex" alignItems="center" as="form" onSubmit={handleSubmitCep}>
                  <Input
                    placeholder="Digite o CEP"
                    size="sm"
                    borderRadius="20px"
                    background="white"
                    height="25px"
                    onChange={handleCepChange}
                    color="black"
                    border="1px solid #D44120"
                    _hover={{ borderColor: theme.colors.primary }}
                    _focus={{ outline: 'none', boxShadow: 'none' }}
                    pr="2rem"
                  />
                  <Button
                    type="submit"
                    position="absolute"
                    right="0px"
                    bg="transparent"
                    zIndex="10000"
                    color="black"
                    _hover={{ color: theme.colors.primary, bg: 'transparent' }}
                    height="100%"
                  >
                    <RiSearchLine />
                  </Button>
                </Box>
              )}
            </Box>
          </Box>

          <Box display={{ base: 'flex', md: 'flex' }} justifyContent="end" alignItems="center">
            <Button onClick={openModal} bg="transparent" color="black" display="flex" padding="0px" justifyContent="center" alignItems="center" position="relative" _hover={{ background: "transparent", color: "#D44120" }}>
              <FaBeer size='25px' color={theme.colors.primaryGreen} />
            </Button>
            {isOpenModal && (
              <Box
                position="fixed"
                top="0"
                left="0"
                width="100%"
                height="100%"
                bg="rgba(0, 0, 0, 0.5)"
                display="flex"
                justifyContent="center"
                alignItems="center"
                zIndex="100000"
              >
                <Box
                  bg="white"
                  borderRadius="8px"
                  boxShadow="lg"
                  p={4}
                  width={{ base: '90%', md: '50%' }}
                >
                  <Box display="flex" justifyContent="space-between" alignItems="center" mb="20px">
                    <Text fontSize="lg" fontWeight="bold" mb={0} color={theme.colors.secondaryGreen}>
                      Escolha o tipo
                    </Text>
                    <Button bg="transparent" color="red" _hover={{ bg: "transparent", transform: "scale(1.3)", }} onClick={closeModal}>
                      <RiCloseCircleLine />
                    </Button>
                  </Box>
                  <Divider/>
                  <Box margin="2% 2% 2% 2%">
                    <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
                      {typesOfCardToSelec.map((filter, index) => (
                        <Link to={`/produtos?filter=${encodeURIComponent(filter.param)}&city_name=${cityName}`} key={index}>
                          <Button
                            border="1px solid"
                            borderColor={theme.colors.logoOrange}
                            color={theme.colors.logoOrange}
                            borderRadius="20px"
                            padding="5px 10px"
                            cursor="pointer"
                            width="100%"
                            backgroundColor="transparent"
                            marginTop="10px"
                            marginRight="10px"
                            _hover={{background: theme.colors.logoOrange, borderColor: theme.colors.logoOrange, color: "white"}}
                          >
                            {filter.cardTitle}
                          </Button>
                        </Link>
                      ))}
                    </SimpleGrid>
                  </Box>
                </Box>
              </Box>
            )}

            <Menu>
              <MenuButton
                bg="transparent"
                display={{ base: 'block', md: 'block' }}
                justifyContent="center"
                alignItems="center"
                padding="0px"
              >
                {user ?
                  <Avatar
                    size={{ base: 'sm', md: 'sm' }}
                    display='block'
                    max-width='100%'
                    border={`2px solid ${theme.colors.primaryGreen}`}
                    src={localStorage.getItem('userPhoto') !== "" ? localStorage.getItem('userPhoto') : 'https://img.freepik.com/free-icon/user_318-159711.jpg'}
                  />
                :
                  <RiUser3Line color={theme.colors.primaryGreen} size={"35px"} textshadow='1px 1px 2px black' />
                }
              </MenuButton>
              <MenuList zIndex='10000' borderColor={theme.colors.orange}>
                {handleMenu()}
              </MenuList>
            </Menu>
            <Button onClick={handleCartOpen} bg="transparent" color="black" display="flex" padding="0px" justifyContent="center" alignItems="center" position="relative" _hover={{ background: "transparent", color: "#D44120" }}>
              <Badge background={theme.colors.primaryGreen} color="white" width="15px" height="15px" position="absolute" top="-20%" right="30%" fontSize="10px" borderRadius="50%">
                {cartItemsCounter}
              </Badge>
              <RiShoppingBasket2Line size='30px' color={theme.colors.primaryGreen} />
            </Button>
          </Box>
        </Flex>
        <Box width='100%' margin='auto'>
          <Search/>
        </Box>
      </Box>
    </>)}

    {/* Menu Drawer */}
    <Drawer placement="left" onClose={handleMenuClose} isOpen={isMenuOpen}>
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader borderBottomWidth='1px' padding="0px 10px" marginBottom="5px">
          {user !== undefined ? (
            <>
              <Text my="7px">Olá, {user.full_name}</Text>
              <DrawerCloseButton/>
            </>
          ) : (
            <>
              <Link to={'/'}>
                <Image objectFit='cover' maxW={{ base: '130px', md: '130px' }} src={logo}  my="5px"/>
              </Link>
              <DrawerCloseButton/>
            </>
          )}
        </DrawerHeader>
        <DrawerBody paddingStart="0px">
          <Flex flexDirection="column" gap="15px">
            {user !== undefined && user?.access === "admin" && (
              <Button
                as={Link}
                to='/admin/dashboard'
                display='flex'
                alignItems='center'
                justifyContent='start'
                gap='10px'
                width='100%'
                bg="transparent"
                color={theme.colors.logoOrange}
                fontWeight="bold"
                borderRadius="0px"
                _hover={{ bg: "transparent", color: "#49b82d" }}
                leftIcon={<RiVipCrown2Line  />} // Replace with your desi#49b82d icon
              >
                Painel Do Admin
              </Button>
            )}

            {user !== undefined && (
              <Button
                as={Link}
                to={`/perfil/${user.id}`}
                display='flex'
                alignItems='center'
                justifyContent='start'
                gap='10px'
                width='100%'
                bg="transparent"
                color={theme.colors.logoOrange}
                fontWeight="bold"
                borderRadius="0px"
                _hover={{ bg: "transparent", color: "#49b82d" }}
                leftIcon={<RiUser3Line />} // Replace with your desi#49b82d icon
              >
                PERFIL
              </Button>
            )}

            <Button
              as="a"
              href='https://instagram.com/boozenoficial?igshid=NzZlODBkYWE4Ng=='
              target='_blank'
              rel="noopener noreferrer"
              display='flex'
              alignItems='center'
              justifyContent='start'
              gap='10px'
              width='100%'
              bg="transparent"
              color={theme.colors.logoOrange}
              fontWeight="bold"
              borderRadius="0px"
              _hover={{ bg: "transparent", color: "#49b82d" }}
              leftIcon={<FaInstagram />}
            >
              INSTA
            </Button>

            <Button
              as={Link}
              to='/quem_somos'
              display='flex'
              alignItems='center'
              justifyContent='start'
              gap='10px'
              width='100%'
              bg="transparent"
              color={theme.colors.logoOrange}
              fontWeight="bold"
              borderRadius="0px"
              _hover={{ bg: "transparent", color: "#49b82d" }}
              leftIcon={<RiQuestionLine  />} // Replace with your desi#49b82d icon
            >
              QUEM SOMOS
            </Button>

            <Button
              onClick={handleBestSellings}
              display='flex'
              alignItems='center'
              justifyContent='start'
              gap='10px'
              width='100%'
              bg="transparent"
              color={theme.colors.logoOrange}
              fontWeight="bold"
              borderRadius="0px"
              _hover={{ bg: "transparent", color: "#49b82d" }}
              leftIcon={<RiStarLine />} // Replace with your desired icon
            >
              DESTAQUES
            </Button>
            {linksCategories.map((card, index) => {
              return (
                <Button
                  key={`btn-${index}`}
                  display='flex'
                  alignItems='center'
                  justifyContent='start'
                  gap='10px'
                  width='100%'
                  bg="transparent"
                  color={theme.colors.logoOrange}
                  fontWeight="bold"
                  borderRadius="0px"
                  _hover={{ bg: "transparent", color: "#49b82d" }}
                  leftIcon={<RiRecordCircleLine />} // Replace with your desired icon
                >
                  <Link to={`/produtos?filter=${encodeURIComponent(card.params)}`} key={index}>
                    {card.title}
                  </Link>
                </Button>
              )
            })}
            <Button
              display='flex'
              alignItems='center'
              justifyContent='start'
              gap='10px'
              width='100%'
              bg="transparent"
              color={theme.colors.logoOrange}
              fontWeight="bold"
              borderRadius="0px"
              _hover={{ bg: "transparent", color: "#49b82d" }}
              onClick={handleScrollToStore}
              leftIcon={<RiRecordCircleLine />}
            >
              BEBIDAS  {/* Add the section ID here */}
            </Button>

          </Flex>
        </DrawerBody>
        {user !== undefined ? (
          <>
            <Button
              bg="red"
              color="white"
              fontWeight='bold'
              width="100%"
              justifyContent="start"
              borderRadius="0px"
              padding="0px"
              _hover={{bg: theme.colors.secondary, color: theme.colors.white500}}
              onClick={() => signOutUser()}
            >
              <RiLogoutBoxLine/> Sair
            </Button>
            <DrawerCloseButton/>
          </>
        ) : (
          <>
            <Button
              as={Link}
              to="/sign_in"
              bg="green"
              color="white"
              fontWeight="bold"
              width="100%"
              justifyContent="start"
              borderRadius="0px"
              padding="0px"
              _hover={{ bg: theme.colors.secondary, color: theme.colors.white500 }}
              leftIcon={<RiLoginBoxLine />}
            >
              Entrar
            </Button>
            <DrawerCloseButton/>
          </>
        )}
      </DrawerContent>
    </Drawer>

    {/* Cart Drawer */}
    <Drawer placement="right" onClose={handleCartClose} isOpen={isCartOpen}>
      <DrawerOverlay />
      <DrawerContent>
          <DrawerHeader borderBottomWidth='1px' marginBottom={4} display="flex" alignItems="center">
          Meu Carrinho
          <DrawerCloseButton/>
        </DrawerHeader>
        <DrawerBody padding="0px">
          <CartComponent/>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  </>);
};

export default React.memo(Navbar);
